import React, { useState } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import usePageTracking from '../pageTrackingHook/usePagetracking';
import Container from '@mui/material/Container'
import Header from './Header'
import MainMenu from './MainMenu'
import Footer from "./Footer"
import "../styles/general.css"
import "../styles/bootstrap3/dist/css/bootstrap.css"
import News from './news/News'
import NewsList from './news/NewsList'
import NominationList from './nominations/NominationList'
import NominationForm from './nominations/NominationForm'
import Contato from './contact/Contact'
import StaticContent from './StaticContent'
import EventList from './event/EventList'
import Event from './event/Event'
import { useGetData } from "../axiosHook/useRequestData";
import {
  ENDERECO_DEFAULT_DATA,
  FOOTER_MENU_DEFAULT_DATA,
  HEADER_MENU_DEFAULT_DATA,
} from '../default-data';
import EventJudgingSheets from './event/EventJudgingSheets'
import CompetitorLevelList from './levels/CompetitorLevelList'
import AffiliateList from './affiliates/AffiliateList'
import EventWorkingOrdersAndResults from './event/EventWorkingOrdersAndResults'
import Ranking from './ranking/Ranking'
import RankingOverallSelector from './ranking/RankingOverallSelector'
import EarningsSearchForm from './campaign/EarningsSearchForm'
import Campaign from './campaign/Campaign'
import EventVideos from './event/EventVideos'
import EventPhotos from './event/EventPhotos'
import Home from './home/Home'
import MemberServices from './members/MemberServices'
import NewMember from './new-member/NewMember'
import EventClassContent from './event/EventClassContent'
import { Alert } from '@mui/material';
import RedirectNoticias from '../redirects/RedirectNoticias';
import RedirectEventos from '../redirects/RedirectEventos';
import RedirectDestaques from '../redirects/RedirectDestaques';
import RedirectRankingMenuTemporada from '../redirects/RedirectRankingMenuTemporada';
import RedirectRankingTemporada from '../redirects/RedirectRankingTemporada';
import RedirectCompetitorLevelList from '../redirects/RedirectCompetitorLevelList';
import RedirectCampaign from '../redirects/RedirectCampaign';
import RedirectMemberServices from '../redirects/RedirectMemberServices';
import LoginPage from './login/LoginPage';
import useSession from '../sessionHook/useSession';
import UpdateMember from './updatememberdata/UpdateMember';
import MainButtons from './home/MainButtons'
import { Box } from '@mui/material'
import EntryForm from './entryform/EntryForm';
import EntryList from './entryform/EntryList';
import HallOfFame from './halloffame/HallOfFame';
import ProgramaMundoRedeas from './mundoRedeas/MundoRedeas';
import MagazineList from './magazine/MagazineList';
import RulebookLinks from './rulebook/RulebookLinks';
import ExtraStallForm from './entryform/ExtraStallForm';
import PatternList from './patterns/PatternList';
import MembershipFeeList from './membership/MembershipFeeList';

const App = (props) => {
  const { helmetContext } = props;
  const [footerMenus, /* setFooterMenus */] = useState(FOOTER_MENU_DEFAULT_DATA);
  const [headerMenus, /* setHeaderMenus */] = useState(HEADER_MENU_DEFAULT_DATA);

  const [enderecoAssociacao = ENDERECO_DEFAULT_DATA] = useGetData('/endereco');

  // Note: The hook rule is disabled as the conditional is on a per build basis, and does not change between renders
  if (process.env.NODE_ENV === 'production') {
    usePageTracking(); // eslint-disable-line react-hooks/rules-of-hooks
  }

  const { session, save, clear } = useSession("ancr");
  let location = useLocation();

  return (
    <>
      <Header session={session} menus={headerMenus} />
      <MainMenu menus={headerMenus} menuStyle={1} />
      <Box sx={{
        display: {
          xs: 'inherit',
          md: 'none',
        }
      }}>
        <MainButtons />
      </Box>
      <Container maxWidth='lg' className='separador'>
        <Routes>
          <Route path="/" element={<Home helmetContext={helmetContext} />} />
          <Route path="/noticias/:id" element={<News key="newsComponent" helmetContext={helmetContext} />} />
          <Route path="/noticias" element={<NewsList key="newsListComponent" helmetContext={helmetContext} />} />
          <Route path="/halldafama" element={<HallOfFame key={location?.key ?? 'halloffame'} helmetContext={helmetContext} />} />
          <Route path="/programamundoredeas" element={<ProgramaMundoRedeas key="programamundoredeas" helmetContext={helmetContext} />} />
          <Route path="/revistaredeas" element={<MagazineList key="revistaredeas" helmetContext={helmetContext} />} />
          <Route path="/regulamento" element={<RulebookLinks key="regulamento" helmetContext={helmetContext} />} />
          <Route path="/nominacoes" element={<NominationList key="nominacaoComponent" helmetContext={helmetContext} />} />
          <Route path="/eventos/:id/fichasjulgamento" element={<EventJudgingSheets key="eventJudgesSheets" helmetContext={helmetContext} />} />
          <Route path="/eventos/:id/resultados/*" element={<EventWorkingOrdersAndResults key="eventResults" helmetContext={helmetContext} ordemEntrada={false} />}>
            <Route path=':idCategoria' element={<EventClassContent helmetContext={helmetContext} ordemEntrada={false} />} />
          </Route>
          <Route path="/eventos/:id/ordementrada" element={<EventWorkingOrdersAndResults key="eventResults" helmetContext={helmetContext} ordemEntrada={true} />}>
            <Route path=':idCategoria' element={<EventClassContent helmetContext={helmetContext} ordemEntrada={true} />} />
          </Route>
          <Route path="/eventos/:id/videos" element={<EventVideos key="eventVideos" helmetContext={helmetContext} />} />
          <Route path="/eventos/:id/fotos" element={<EventPhotos key="eventPhotos" helmetContext={helmetContext} />} />
          <Route path="/eventos/:id" element={<Event key="eventComponent" helmetContext={helmetContext} />} />
          <Route path="/eventos" element={<EventList key={location?.key ?? 'eventListComponent'} helmetContext={helmetContext} />} />
          <Route path="/niveis" element={<CompetitorLevelList key="competitorLevelList" helmetContext={helmetContext} />} />
          <Route path="/nucleos" element={<AffiliateList key="affiliateList" helmetContext={helmetContext} />} />
          <Route path="/formularionominacao" element={<NominationForm key="nominationform" helmetContext={helmetContext} />} />
          <Route path="/ranking" element={<Ranking key="ranking" helmetContext={helmetContext} />} />
          <Route path="/rankingacumulado" element={<RankingOverallSelector key="rankingOverallSelector" helmetContext={helmetContext} />} />
          <Route path="/campanhas" element={<EarningsSearchForm key="earningsSearchForm" helmetContext={helmetContext} />} />
          <Route path="/animais/:id" element={<Campaign key="campaign" helmetContext={helmetContext} horse={true} />} />
          <Route path="/competidores/:id" element={<Campaign key="campaign" helmetContext={helmetContext} horse={false} />} />
          <Route path="/percursos" element={<PatternList key="patterns" helmetContext={helmetContext} />} />

          {/*Páginas estáticas*/
            [...new Set(footerMenus.map(item => item.itens))].map((rota, index) =>
              rota.length > 0
                ? rota.map(link =>
                  link.static &&
                  /*  gerar Rota para cada página estática */
                  <Route key={`${link.url.slice(1)}-${index}`} path={link.url} element={<StaticContent helmetContext={helmetContext} id={link.id} page={link.page} link={link.url} />} />
                )
                : ''
            )
          }

          <Route path="/contato" element={<Contato key="contatoComponent" helmetContext={helmetContext} enderecoAssociacao={enderecoAssociacao} />} />
          <Route path="/juizes" element={<MemberServices key="juizes" helmetContext={helmetContext} juizes />} />
          <Route path="/treinadores" element={<MemberServices key="treinadores" helmetContext={helmetContext} juizes={false} />} />
          <Route path="/afiliar" element={<NewMember key="contatoComponent" helmetContext={helmetContext} />} />
          <Route path="/login" element={<LoginPage key="loginComponent" helmetContext={helmetContext} enderecoAssociacao={enderecoAssociacao} session={session} save={save} clear={clear} />} />
          <Route path="/atualizar" element={<UpdateMember key="atualizarComponent" helmetContext={helmetContext} enderecoAssociacao={enderecoAssociacao} session={session} clear={clear} />} />
          <Route path="/anuidades" element={<MembershipFeeList key="anuidades" helmetContext={helmetContext} session={session} save={save} clear={clear} />} />
          <Route path="/inscricoes" element={<EntryForm key="inscricoes" helmetContext={helmetContext} session={session} save={save} clear={clear} />} />
          <Route path="/baiaextra" element={<ExtraStallForm key="baiaextra" helmetContext={helmetContext} session={session} save={save} clear={clear} />} />
          <Route path="/efetuar" element={<EntryList key="efetuar" helmetContext={helmetContext} session={session} save={save} clear={clear} />} />

          {/* start redirects from old site urls */}
          <Route path="/noticias.asp" element={<RedirectNoticias />} />
          <Route path="/eventos.asp" element={<RedirectEventos />} />
          <Route path="/resultados.asp" element={<RedirectEventos resultados />} />
          <Route path="/ordementrada.asp" element={<RedirectEventos ordemEntrada />} />
          <Route path="/fichasjulgamento.asp" element={<RedirectEventos fichasJulgamento />} />
          <Route path="/videos.asp" element={<RedirectEventos videos />} />
          <Route path="/fotoseventos.asp" element={<RedirectEventos fotos />} />
          <Route path="/fotoseventosindex.asp" element={<RedirectEventos fotos />} />
          <Route path="/afiliarse.asp" element={<Navigate to="/afiliar" />} />
          <Route path="/associacoes.asp" element={<Navigate to="/nucleos" />} />
          <Route path="/destaques.asp" element={<RedirectDestaques menus={footerMenus} />} />
          <Route path="/ranking_temporada_picker.asp" element={<Navigate to="/ranking" />} />
          <Route path="/rankingacumulado.asp" element={<Navigate to="/rankingacumulado" />} />
          <Route path="/ranking_menu_temporada.asp" element={<RedirectRankingMenuTemporada />} />
          <Route path="/ranking_temporada.asp" element={<RedirectRankingTemporada />} />
          <Route path="/buscarcampanha.asp" element={<Navigate to="/campanhas?animais=1" />} />
          <Route path="/nominacao.asp" element={<Navigate to="/formularionominacao" />} />
          <Route path="/listanominacoes.asp" element={<Navigate to="/nominacoes" />} />
          <Route path="/listaniveis.asp" element={<RedirectCompetitorLevelList />} />
          <Route path="/contato.asp" element={<Navigate to="/contato" />} />
          <Route path="/campanhacavalo.asp" element={<RedirectCampaign horse />} />
          <Route path="/campanhacompetidor.asp" element={<RedirectCampaign />} />
          <Route path="/servicos.asp" element={<RedirectMemberServices />} />
          {/* end redirects from old site urls */}

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <Alert severity='error'>404: Página não localizada! Verifique o endereço</Alert>
              </main>
            }
          />
        </Routes>
      </Container>
      <Footer menus={footerMenus} enderecoAssociacao={enderecoAssociacao} />
    </>
  )
}

export default App;
